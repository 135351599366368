import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import LandingNav from '../components/LandingNav';
import Footer from '../components/Footer';
import { Accordion, AccordionItem } from '../components/Accordion';
import LandingDrawer from '../components/LandingDrawer';

export default function Cijenovnik() {
    const { user } = useAuth();

    return (
        <div className='relative flex-grow flex flex-col  '>
            <Helmet>
                <title>NapomeniMe - Cijenovnik</title>
                <meta name="description" content="Mjesečna pretplata od 50 BAM plus 0.15 BAM po SMS poruci. Fleksibilna dopuna SMS kredita bez isteka." />
                <meta name="keywords" content="cijenovnik NapomeniMe, cijena SMS podsjetnika, zakazivanje termina, cijena softvera, prepaid SMS krediti" />
            </Helmet>

            <div>
                <div
                    className='absolute top-0 left-0 w-full min-h-screen bg-no-repeat bg-top sm:mt-0 mt-[100px]'
                    style={{
                        backgroundImage: "url('/images/webbg.png')",
                        backgroundSize: 'cover', // Ensures the image covers the entire container
                        backgroundAttachment: 'scroll', // Makes the background scroll with the content
                        zIndex: -1, // Ensures the background image stays behind other content
                    }}
                >
                </div>

                <div className=''>
                    <LandingNav />
                    <div className='w-full items-center justify-center flex mt-[100px]'>

                        <div className='flex items-center justify-center w-full flex-col px-4'>
        
                            <div className='bg-gray-100 border shadow-xl p-2 py-12 max-w-[600px] rounded-lg w-full flex items-center justify-center flex-col '>
                                <h1 className='text-black font-bold text-3xl '>Cijenovnik</h1>
                                <div className='my-2 border p-4 bg-gray-200 rounded w-[95%] sm:w-[85%] '>
                                    <span className='text-black font-bold text-2xl'>50 <span className='text-base'>BAM</span> Mjesečno + 0.15<span className='text-base'>BAM</span>/SMS poruci</span>
                                </div>
                                <a href='/register' className='bg-blue-950 rounded-lg p-4  w-[95%] sm:w-[85%]  mt-4 text-center'>Napravi nalog</a>

                                <p className='text-gray-500 mx-7 mt-4'>
                                Naš cijenovnik nudi jednostavne i transparentne cijene za korištenje naše usluge. Mjesečna pretplata iznosi 50 BAM, koja uključuje pristup svim funkcionalnostima softvera. Pored toga, cijena slanja SMS poruka je 0.15 BAM po poruci. SMS krediti se unaprijed uplaćuju kao prepaid dopuna zajedno sa mjesečnom cijenom softvera. Važno je napomenuti da vaši SMS krediti nikada ne ističu – neiskorišteni krediti automatski se prenose u naredni mjesec, omogućavajući vam fleksibilno upravljanje troškovima i resursima.
                                </p>
                            </div>


                        </div>
                    </div>

                </div>
            </div>



            <div className='mt-[350px]'>
                <Footer />
            </div>





        </div>
    );
}
