import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from '../axios';
import { useAuth } from '../contexts/AuthContext';
import DatePicker from '../components/DatePicker';
import ConfirmModal from './ConfirmModal';


export default function EditTermin({ isOpened, setIsOpened, termin, termini, setTermini, toastMessage, isToastOpen, isToastError, setIsToastError, setIsToastOpen, setToastMessage, dohvatiTermine }) {
    const { user } = useAuth();
    const today = new Date();
    const maxLength = 160;

    const [vrijemeTermina, setVrijemeTermina] = useState(
        `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
    );
    const [spesificnoVrijemeSlanjaPoruke, setSpesificnoVrijemeSlanjaPoruke] = useState(
        `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
    );

    const [isConfirmOpened, setIsConfirmOpened] = React.useState(false);
    const [selectedTerminData, setSelectedTerminData] = React.useState();



    const [formData, setFormData] = useState({
        hours: '',
        minutes: '',
        clientName: '',
        phone: '',
        notificationTime: 'ostalo',
        message: '',
        spesificMinutes: '',
        spesificHours: '',
    });

    const [errors, setErrors] = useState({
        hours: '',
        minutes: '',
        phone: '',
        message: '',
        spesificMH: ""
    });

    const [apiError, setApiError] = useState("");




    React.useEffect(() => {
        if (termin) {
            const timePart = termin.vrijemeTermina.split(" ")[1];
            const [hours, minutes] = timePart.split(":");

            const spesifictimePart = termin.vrijemeSlanjaPoruke.split(" ")[1];
            const [spesifichours, spesificminutes] = spesifictimePart.split(":");

            const datumTermina = termin.vrijemeTermina.split(" ")[0];
            const datumPoruke = termin.vrijemeSlanjaPoruke.split(" ")[0];

            setFormData(prev => ({
                ...prev,
                phone: termin.telefon || '',
                clientName: termin.imeKlijenta || '',
                message: termin.poruka || '',
                hours: hours,
                minutes: minutes,
                spesificHours: spesifichours,
                spesificMinutes: spesificminutes,

            }));

            setVrijemeTermina(datumTermina);
            setSpesificnoVrijemeSlanjaPoruke(datumPoruke);
            setSelectedTerminData(termin)
        }
    }, [termin]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value.replace(/[^0-9]/g, '').slice(0, 2)
        }));
    };


    const handlePhoneChange = (e) => {
        const value = e.target.value;
        // Allow only numbers and '+' character
        const regex = /^[0-9+]*$/;
        if (regex.test(value)) {
            setFormData({ ...formData, phone: value });
            setErrors({ ...errors, phone: '' });
        }
    };

    const handleNotificationChange = (value) => {
        setFormData(prev => ({
            ...prev,
            notificationTime: value,
        }));
    };




    const calculateTimes = (formData) => {
        const { hours, minutes, notificationTime, spesificHours, spesificMinutes } = formData;

        let [datePart, timePart] = vrijemeTermina.split(' ');
        if (!timePart) {
            timePart = `${hours || '00'}:${minutes || '00'}`;
        }
        const [year, month, day] = datePart.split('-').map(Number);
        const [terminHours, terminMinutes] = timePart.split(':').map(Number);

        const terminDate = new Date(year, month - 1, day, hours ? parseInt(hours, 10) : terminHours, minutes ? parseInt(minutes, 10) : terminMinutes);


        const finalTermineTime = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(terminDate.getHours()).padStart(2, '0')}:${String(terminDate.getMinutes()).padStart(2, '0')}`;

        let finalNotificationTime = '';

        if (notificationTime === "ostalo") {

            const specHours = spesificHours ? parseInt(spesificHours, 10) : 0;
            const specMinutes = spesificMinutes ? parseInt(spesificMinutes, 10) : 0;


            const [specDatePart, specTimePart] = spesificnoVrijemeSlanjaPoruke.split(' ');

            finalNotificationTime = `${specDatePart} ${String(specHours).padStart(2, '0')}:${String(specMinutes).padStart(2, '0')}`;
        } else if (notificationTime && !isNaN(notificationTime)) {

            const notificationMinutes = parseInt(notificationTime, 10);

            const notificationDate = new Date(terminDate);
            notificationDate.setMinutes(notificationDate.getMinutes() - notificationMinutes);

            finalNotificationTime = `${notificationDate.getFullYear()}-${String(notificationDate.getMonth() + 1).padStart(2, '0')}-${String(notificationDate.getDate()).padStart(2, '0')} ${String(notificationDate.getHours()).padStart(2, '0')}:${String(notificationDate.getMinutes()).padStart(2, '0')}`;
        }

        return { finalTermineTime, finalNotificationTime };
    };

    const handleSubmit = async (e) => {

        setErrors({
            hours: '',
            minutes: '',
            phone: '',
            message: ''
        });

        e.preventDefault();

        // Validation
        const newErrors = {};
        let phone = formData.phone;
        if (!formData.hours || !formData.minutes) newErrors.hours = 'Vrijeme termina je obavezan';
        if (formData.hours > 23) {
            newErrors.hours = 'Molimo unesite tačan termin'
        }
        if (formData.minutes > 59) {
            newErrors.hours = 'Molimo unesite tačan termin'
        }

        if (formData.spesificHours > 23) {
            newErrors.spesificMH = 'Molimo unesite tačano vrijeme slanja poruke'
        }
        if (formData.spesificMinutes > 59) {
            newErrors.spesificMH = 'Molimo unesite tačano vrijeme slanja poruke'
        }
        if (!formData.phone) newErrors.phone = 'Telefon je obavezan!';
        if (!formData.message) newErrors.message = 'Poruka je obavezna!';
        if (!formData.phone.startsWith("+387")) {
            if (formData.phone.startsWith("387")) {
                phone = `+${phone}`;
            }
            else {
                newErrors.phone = 'Molimo unesite telefon u +387 formatu'
            }
        }
        if (phone.length < 5) {
            newErrors.phone = 'Molimo unesite tačan broj telefona'
        }

        if (formData.notificationTime === "ostalo") {
            if (!formData.spesificMinutes || !formData.spesificHours) {
                newErrors.spesificMH = 'Molimo unesite vrijeme slanja poruke';
            }

        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }


        const { finalTermineTime, finalNotificationTime } = calculateTimes(formData);

        const body = {
            "imeKlijenta": formData.clientName,
            "telefon": phone,
            "poruka": formData.message,
            "vrijemeTermina": finalTermineTime,
            "vrijemeSlanjaPoruke": finalNotificationTime,
            "terminId": termin.id
        };
        try {
            const response = await axios.post('/urediTermin', body);
            console.log(response)
            console.log(formData)
            if (response.status === 200) {
                setToastMessage('Termin je uspješno sačuvan');
                setIsToastOpen(true);
                dohvatiTermine();

            }

        } catch (error) {
            setApiError("Neki problem se dogodio, molim vas probajte kasnije.");
        }






    };

    return (
        <div className={`${isOpened ? "" : "hidden"}`}>
            <ConfirmModal isConfirmOpened={isConfirmOpened} setIsConfirmOpened={setIsConfirmOpened} termin={termin} termini={termini} setTermini={setTermini} setIsOpened={setIsOpened} toastMessage={toastMessage} isToastOpen={isToastOpen} setIsToastOpen={setIsToastOpen} isToastError={isToastError} setIsToastError={setIsToastError} setToastMessage={setToastMessage} />

            <div className={`z-[9999]   bg-[#F6F4F7] shadow-lg w-full sm:w-[400px] border-l-gray-400 sm:border-[1px]  top-0 right-0  absolute  h-full transform transition-transform ${isOpened ? 'translate-x-0' : 'translate-x-full'}`} >
                <div className='w-full items-center justify-between flex p-4 '>
                    <span className='text-gray-800 text-lg font-semibold'>Uredi termin</span>
                    <button onClick={() => setIsOpened(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-6 stroke-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className='bg-white p-4 rounded-lg shadow-lg md:mt-12 w-[95%]   mx-auto  border border-gray-200'>
                    <span className='text-black font-semibold text-lg'>Podaci klijenta</span>
                    <div>
                        <div className='mt-4'>
                            <div className="mb-3 flex flex-col">
                                <span className='text-black'>Vrijeme termina:</span>
                                <div className='flex items-center mt-2'>
                                    <div><DatePicker selectedDate={vrijemeTermina} setSelectedDate={setVrijemeTermina} /></div>
                                    <input
                                        type="text"
                                        name="hours"
                                        value={formData.hours}
                                        placeholder="00"
                                        className={`w-10 h-[42px]   ml-4 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
                                        onChange={handleChange}
                                    />
                                    <span className='text-black'>h</span>
                                    <input
                                        type="text"
                                        name="minutes"
                                        value={formData.minutes}
                                        placeholder="00"
                                        className={`w-10 h-[42px]   ml-1 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
                                        onChange={handleChange}
                                    />
                                    <span className='text-black'>m</span>
                                </div>
                                {errors.hours && <span className='text-red-500'>{errors.hours}</span>}
                            </div>

                            <div className='flex-col flex'>
                                <label className='text-black'>Ime klijenta</label>
                                <input
                                    type="text"
                                    name="clientName"
                                    value={formData.clientName}
                                    className='rounded px-2 py-2 bg-slate-100 mt-[1px] text-black'
                                    placeholder='Nije obavezno'
                                    onChange={(e) => setFormData({ ...formData, clientName: e.target.value })}
                                />
                            </div>
                            <div className='flex-col flex mt-3'>
                                <label className='text-black'>Telefon</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    className={`rounded px-2 py-2 bg-slate-100 mt-[1px] text-black ${errors.phone ? 'border-red-500' : ''}`}
                                    placeholder='+387xxxxxxxx'
                                    onChange={handlePhoneChange}
                                />
                                {errors.phone && <span className='text-red-500'>{errors.phone}</span>}
                            </div>
                            <div className='flex-col flex mt-3'>
                                <label className='text-black'>Datum i vrijeme slanja poruke:</label>
                                <div className='flex flex-col mt-3'>



                                    <div className='flex items-center '>
                                        <div><DatePicker selectedDate={spesificnoVrijemeSlanjaPoruke} setSelectedDate={setSpesificnoVrijemeSlanjaPoruke} /></div>
                                        <input
                                            type="text"
                                            name="spesificHours"
                                            value={formData.spesificHours}
                                            placeholder="00"
                                            className={`w-10 h-[42px]   ml-4 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
                                            onChange={handleChange}
                                        />
                                        <span className='text-black'>h</span>
                                        <input
                                            type="text"
                                            name="spesificMinutes"
                                            value={formData.spesificMinutes}
                                            placeholder="00"
                                            className={`w-10 h-[42px]   ml-1 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
                                            onChange={handleChange}
                                        />
                                        <span className='text-black'>m</span>
                                    </div>
                                    {errors.spesificMH && <span className='text-red-500'>{errors.spesificMH}</span>}
                                </div>
                            </div>

                            <div className='flex-col flex mt-3'>
                                <label className='text-black'>Poruka</label>
                                <div className='w-full'>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        className={`rounded px-2 py-2 bg-slate-100 mt-[1px] w-full text-black ${errors.message ? 'border-red-500' : ''}`}
                                        placeholder=''
                                        onChange={(e) => {
                                            const text = e.target.value;

                                            // Function to replace specific characters with GSM-7 equivalents
                                            const replaceSpecialChars = (text) => {
                                                return text
                                                    .replace(/č/g, 'c')
                                                    .replace(/š/g, 's')
                                                    .replace(/ć/g, 'c')
                                                    .replace(/đ/g, 'd')
                                                    .replace(/ž/g, 'z');
                                            };

                                            // Function to filter out non-whitelisted characters
                                            const filterAllowedChars = (text) => {
                                                return text
                                                    .split('')
                                                    .filter(char => /[A-Za-z0-9\s!.,()@\-_$?+*:"']/g.test(char)) // Whitelist allowed characters
                                                    .join('');
                                            };

                                            // Replace specific characters and then filter out non-whitelisted characters
                                            let processedText = replaceSpecialChars(text);
                                            processedText = filterAllowedChars(processedText);

                                            // Limit the message length to maxLength characters
                                            if (processedText.length > maxLength) {
                                                processedText = processedText.substring(0, maxLength);
                                            }

                                            // Update the form data with the processed message
                                            setFormData({ ...formData, message: processedText });
                                        }}
                                        maxLength={maxLength} // Optionally enforce limit at the HTML level
                                    />
                                    <div className={`text-sm  mt-1 ${formData.message.length === 160 ? "text-red-400" : "text-gray-600"}`}>
                                        {formData.message.length}/{maxLength}
                                    </div>
                                </div>





                                {errors.message && <span className='text-red-500'>{errors.message}</span>}
                                {apiError && <span className='text-red-500'>{apiError}</span>}
                            </div>

                            <div className='text-black mt-4'>
                                <span>Status poruke:</span>

                                {selectedTerminData ?
                                    <div className='mt-2 flex items-start flex-col '>
                                        <div className='flex items-center'>  
                                            <div className={`size-2 rounded-[50px]   ${selectedTerminData.status === "pending" ? "bg-gray-500 " : selectedTerminData.status === "sent" ? "bg-green-500 " : "bg-red-500"}`}></div>
                                            <span className={`ml-1  ${selectedTerminData.status === "pending" ? "text-gray-500 " : selectedTerminData.status === "sent" ? "text-gray-500 " : "text-gray-500"}`}>{selectedTerminData.status === "pending" ? "Još nije poslata " : selectedTerminData.status === "sent" ? "Uspješno poslata " : "Nije poslata"}</span></div>
                                        <div>
                                            <span>{selectedTerminData.statusText}</span>
                                        </div>
                                    </div> : ""}

                            </div>

                            <div className='w-full justify-between flex mt-6'>
                                <button type="submit" className='border-red-500 border-2 font-semibold text-red-500 rounded p-2 text-sm px-4' onClick={() => setIsConfirmOpened(true)}>Izbriši</button>
                                <button type="submit" className='bg-blue-500 rounded p-2 text-sm px-4' onClick={handleSubmit}>Sačuvaj</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}