import React, {useState, useEffect} from 'react';
import settings from '../settings';
function SignIn({BtnText, isLogin}) {

    const [loginUrl, setLoginUrl] = useState(null);
    
    useEffect(() => {
        const authType = isLogin ? 'login' : 'register';
        const stateParam = JSON.stringify({ type: authType });
        fetch(`${settings.backendUrl}/api/auth?type=${authType}&state=${encodeURIComponent(stateParam)}`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLoginUrl( data.url ))
            .catch((error) => console.error(error));
    }, []);

    return (
        <div>
            <a href={loginUrl} className=' items-center justify-around flex w-[250px] bg-insideui text-black p-2 rounded'>
                 <img  src='/images/googlelogo.png' className='size-8' />
                {BtnText}
            </a>
        </div>
    );
}

export default SignIn;