import React from 'react';
import { Outlet } from 'react-router-dom';
import Drawer from './Drawer';

export default function MainLayout() {
    return (
        <div className="bg-white min-h-screen flex flex-col">
            <div className="flex flex-grow">
                <div className="fixed z-[15]">
                    <Drawer />
                </div>
                <div className="ml-[0px] lg:ml-[250px] w-full flex flex-col ">
                    <div className="p-4 lg:p-12 mt-10 lg:mt-0 flex flex-col flex-grow">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}
