import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Drawer from '../components/Drawer';
import { Helmet } from 'react-helmet-async';
import Calendar from '../components/Calendar';
import DatePicker from '../components/DatePicker';
import axios from '../axios';
import Toast from '../components/Toast';
import { set } from 'date-fns';

export default function Dodajtermin() {
	const { user } = useAuth();
	const today = new Date();
	const maxLength = 160;
	const [templetes, setTempletes] = React.useState();
	const [vrijemeTermina, setVrijemeTermina] = useState(
		`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
	);
	const [spesificnoVrijemeSlanjaPoruke, setSpesificnoVrijemeSlanjaPoruke] = useState(
		`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
	);

	const [toastMessage, setToastMessage] = useState('');
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [templateMessage, setTemplateMessage] = useState('');

	const [formData, setFormData] = useState({
		hours: '',
		minutes: '',
		clientName: '',
		phone: '',
		notificationTime: '120',
		message: '',
		spesificMinutes: '',
		spesificHours: '',
	});

	const [errors, setErrors] = useState({
		hours: '',
		minutes: '',
		phone: '',
		message: '',
		spesificMH: ""
	});

	const [apiError, setApiError] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({
			...prev,
			[name]: value.replace(/[^0-9]/g, '').slice(0, 2)
		}));
	};


	const dohvatiTemplejte = async () => {
		try {

			const odgovor = await axios.get('/templetes');
			setTempletes(odgovor.data);
			console.log(odgovor.data)

		} catch (error) {
			console.error("Greška prilikom dohvatanja termina:", error);
		}
	};

	React.useEffect(() => {
		dohvatiTemplejte();
	}, []);



	React.useEffect(() => {
		if (templateMessage !== '' ) {
			const clientDetails = formData.clientName.split(' ');
			const ime = clientDetails[0] || '';
			const prezime = clientDetails[1] || '';

			// Vrijeme termina (ovdje možeš dodati dodatnu logiku ako je potrebno)
			const vrijemeTermina = `${formData.hours || 'HH'}:${formData.minutes || 'MM'}`;

			// Zamjena varijabli u template poruci
			let updatedMessage = templateMessage
				.replace('(ime)', ime)
				.replace('(prezime)', prezime)
				.replace('(vrijemetermina)', vrijemeTermina);

			// Ažuriraj formData s novom porukom
			setFormData((prevData) => ({
				...prevData,
				message: updatedMessage,
			}));
		}
	}, [formData.clientName, formData.hours, formData.minutes, templateMessage]);


	const handlePhoneChange = (e) => {
		const value = e.target.value;
		// Allow only numbers and '+' character
		const regex = /^[0-9+]*$/;
		if (regex.test(value)) {
			setFormData({ ...formData, phone: value });
			setErrors({ ...errors, phone: '' });
		}
	};

	const handleNotificationChange = (value) => {
		setFormData(prev => ({
			...prev,
			notificationTime: value,
		}));
	};


	const handleTemplateChange = (e) => {
		setTemplateMessage(e.target.value);
		setFormData(prev => ({
			...prev,
			message: e.target.value,
		}));
	};




	const calculateTimes = (formData) => {
		const { hours, minutes, notificationTime, spesificHours, spesificMinutes } = formData;

		let [datePart, timePart] = vrijemeTermina.split(' ');
		if (!timePart) {
			timePart = `${hours || '00'}:${minutes || '00'}`;
		}
		const [year, month, day] = datePart.split('-').map(Number);
		const [terminHours, terminMinutes] = timePart.split(':').map(Number);

		const terminDate = new Date(year, month - 1, day, hours ? parseInt(hours, 10) : terminHours, minutes ? parseInt(minutes, 10) : terminMinutes);


		const finalTermineTime = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(terminDate.getHours()).padStart(2, '0')}:${String(terminDate.getMinutes()).padStart(2, '0')}`;

		let finalNotificationTime = '';

		if (notificationTime === "ostalo") {

			const specHours = spesificHours ? parseInt(spesificHours, 10) : 0;
			const specMinutes = spesificMinutes ? parseInt(spesificMinutes, 10) : 0;


			const [specDatePart, specTimePart] = spesificnoVrijemeSlanjaPoruke.split(' ');

			finalNotificationTime = `${specDatePart} ${String(specHours).padStart(2, '0')}:${String(specMinutes).padStart(2, '0')}`;
		} else if (notificationTime && !isNaN(notificationTime)) {

			const notificationMinutes = parseInt(notificationTime, 10);

			const notificationDate = new Date(terminDate);
			notificationDate.setMinutes(notificationDate.getMinutes() - notificationMinutes);

			finalNotificationTime = `${notificationDate.getFullYear()}-${String(notificationDate.getMonth() + 1).padStart(2, '0')}-${String(notificationDate.getDate()).padStart(2, '0')} ${String(notificationDate.getHours()).padStart(2, '0')}:${String(notificationDate.getMinutes()).padStart(2, '0')}`;
		}

		return { finalTermineTime, finalNotificationTime };
	};

	const handleSubmit = async (e) => {

		setErrors({
			hours: '',
			minutes: '',
			phone: '',
			message: ''
		});

		e.preventDefault();

		// Validation
		const newErrors = {};
		let phone = formData.phone;
		if (!formData.hours || !formData.minutes) newErrors.hours = 'Vrijeme termina je obavezan';
		if (formData.hours > 23) {
			newErrors.hours = 'Molimo unesite tačan termin'
		}
		if (formData.minutes > 59) {
			newErrors.hours = 'Molimo unesite tačan termin'
		}

		if (formData.spesificHours > 23) {
			newErrors.spesificMH = 'Molimo unesite tačano vrijeme slanja poruke'
		}
		if (formData.spesificMinutes > 59) {
			newErrors.spesificMH = 'Molimo unesite tačano vrijeme slanja poruke'
		}
		if(formData.message.length > 159){
			newErrors.message = "Poruka je preko 160 karaktera."
		}
		if (!formData.phone) newErrors.phone = 'Telefon je obavezan!';
		if (!formData.message) newErrors.message = 'Poruka je obavezna!';
		if (!formData.phone.startsWith("+387")) {
			if (formData.phone.startsWith("387")) {
				phone = `+${phone}`;
			}
			else {
				newErrors.phone = 'Molimo unesite telefon u +387 formatu'
			}
		}
		if (phone.length < 5) {
			newErrors.phone = 'Molimo unesite tačan broj telefona'
		}

		if (formData.notificationTime === "ostalo") {
			if (!formData.spesificMinutes || !formData.spesificHours) {
				newErrors.spesificMH = 'Molimo unesite vrijeme slanja poruke';
			}

		}

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}


		const { finalTermineTime, finalNotificationTime } = calculateTimes(formData);

		const body = {
			"imeKlijenta": formData.clientName,
			"telefon": phone,
			"poruka": formData.message,
			"vrijemeTermina": finalTermineTime,
			"vrijemeSlanjaPoruke": finalNotificationTime,
		};
		try {
			const response = await axios.post('/napraviTermin', body);
			console.log(response)
			console.log(formData)
			if (response.status === 200) {
				setToastMessage('Termin je uspješno dodan.');
				setIsToastOpen(true);
				setTemplateMessage("")
				setFormData({
					hours: '',
					minutes: '',
					clientName: '',
					phone: '',
					notificationTime: '120',
					message: '',
					spesificMinutes: '',
					spesificHours: '',
				})
			}

		} catch (error) {
			setApiError("Neki problem se dogodio, molim vas probajte kasnije.");
			console.log(error)
		}

	};

	return (
		<div className='flex-grow flex flex-col'>
			<Toast message={toastMessage} isOpen={isToastOpen} setIsOpen={setIsToastOpen} isToastError={false} />
			<Helmet>
				<title>Dodaj novi termin</title>
			</Helmet>
			<div className='flex-grow flex flex-col'>
				<h1 className='text-black font-bold text-2xl mb-6'>Dodaj novi termin</h1>
				<div className='flex items-center xl:items-start justify-between xl:space-x-12 flex-col xl:flex-row'>

					<div className='bg-white p-4 rounded-lg shadow-lg w-[100%] xl:mt-0 mt-4 xl:w-[90%] border border-gray-200'>
						<span className='text-black font-semibold text-lg'>Podaci klijenta</span>
						<div>
							<div className='mt-4'>
								<div className="mb-3 flex flex-col">
									<span className='text-black'>Vrijeme termina:</span>
									<div className='flex items-center mt-2'>
										<div><DatePicker selectedDate={vrijemeTermina} setSelectedDate={setVrijemeTermina} /></div>
										<input
											type="text"
											name="hours"
											value={formData.hours}
											placeholder="00"
											className={`w-10 h-[42px]   ml-4 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
											onChange={handleChange}
										/>
										<span className='text-black'>h</span>
										<input
											type="text"
											name="minutes"
											value={formData.minutes}
											placeholder="00"
											className={`w-10 h-[42px]   ml-1 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
											onChange={handleChange}
										/>
										<span className='text-black'>m</span>
									</div>
									{errors.hours && <span className='text-red-500'>{errors.hours}</span>}
								</div>

								<div className='flex-col flex'>
									<label className='text-black'>Ime klijenta</label>
									<input
										type="text"
										name="clientName"
										value={formData.clientName}
										className='rounded px-2 py-2 bg-slate-100 mt-[1px] text-black'
										placeholder='Nije obavezno'
										onChange={(e) => setFormData({ ...formData, clientName: e.target.value })}
									/>
								</div>
								<div className='flex-col flex mt-3'>
									<label className='text-black'>Telefon</label>
									<input
										type="text"
										name="phone"
										value={formData.phone}
										className={`rounded px-2 py-2 bg-slate-100 mt-[1px] text-black ${errors.phone ? 'border-red-500' : ''}`}
										placeholder='+387xxxxxxxx'
										onChange={handlePhoneChange}
									/>
									{errors.phone && <span className='text-red-500'>{errors.phone}</span>}
								</div>
								<div className='flex-col flex mt-3'>
									<label className='text-black'>Poruku ranije  pošalji prije termina</label>
									<div className='mt-1 flex flex-wrap gap-2'>
										<button
											type="button"
											onClick={() => handleNotificationChange('1440')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '1440' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											24 sata
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('480')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '480' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											8 sati
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('240')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '240' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											4 sata
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('120')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '120' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											2 sata
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('60')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '60' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											1 sat
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('45')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '45' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											45m
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('30')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === '30' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											30m
										</button>
										<button
											type="button"
											onClick={() => handleNotificationChange('ostalo')}
											className={`rounded w-16 h-10 shadow-sm ${formData.notificationTime === 'ostalo' ? 'bg-blue-400' : 'bg-gray-200 text-black'}`}
										>
											ostalo
										</button>
									</div>
									{formData.notificationTime === 'ostalo' && (
										<div className='flex flex-col mt-3'>
											<span className='text-gray-900'>Unesite tačno vrijeme slanja poruke</span>


											<div className='flex items-center mt-2'>
												<div><DatePicker selectedDate={spesificnoVrijemeSlanjaPoruke} setSelectedDate={setSpesificnoVrijemeSlanjaPoruke} /></div>
												<input
													type="text"
													name="spesificHours"
													value={formData.spesificHours}
													placeholder="00"
													className={`w-10 h-[42px]   ml-4 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
													onChange={handleChange}
												/>
												<span className='text-black'>h</span>
												<input
													type="text"
													name="spesificMinutes"
													value={formData.spesificMinutes}
													placeholder="00"
													className={`w-10 h-[42px]   ml-1 border bg-slate-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-black ${errors.hours ? 'border-red-500' : ''}`}
													onChange={handleChange}
												/>
												<span className='text-black'>m</span>
											</div>
											{errors.spesificMH && <span className='text-red-500'>{errors.spesificMH}</span>}
										</div>
									)}
								</div>

								<div className='flex-col flex mt-6'>
									<div className='flex flex-col'>
										<label htmlFor="options" className='text-black'>Templjeti poruke (opcijalno)</label>
										<select id="options" className='max-w-[150px] bg-gray-200 text-gray-800 p-2 rounded-lg' onChange={handleTemplateChange} value={templateMessage} >
											<option value="">Select an option</option>
										
			

											{templetes ? templetes.map((templete) => (
													<option value={templete.templetePoruka}>{templete.templetePoruka}</option>
											)) : ""}
										</select>

									</div>
									<label className='text-black'>Poruka</label>
									<div className='w-full'>
										<textarea
											name="message"
											value={formData.message}
											className={`rounded px-2 py-2 bg-slate-100 mt-[1px] w-full text-black ${errors.message ? 'border-red-500' : ''}`}
											placeholder=''
											onChange={(e) => {
												const text = e.target.value;

												// Function to replace specific characters with GSM-7 equivalents
												const replaceSpecialChars = (text) => {
													return text
														.replace(/č/g, 'c')
														.replace(/š/g, 's')
														.replace(/ć/g, 'c')
														.replace(/đ/g, 'd')
														.replace(/ž/g, 'z');
												};

												// Function to filter out non-whitelisted characters
												const filterAllowedChars = (text) => {
													return text
														.split('')
														.filter(char => /[A-Za-z0-9\s!.,()@\-_$?+*:"']/g.test(char)) // Whitelist allowed characters
														.join('');
												};

												// Replace specific characters and then filter out non-whitelisted characters
												let processedText = replaceSpecialChars(text);
												processedText = filterAllowedChars(processedText);

												// Limit the message length to maxLength characters
												if (processedText.length > maxLength) {
													processedText = processedText.substring(0, maxLength);
												}

												// Update the form data with the processed message
												setFormData({ ...formData, message: processedText });
											}}
											maxLength={maxLength} // Optionally enforce limit at the HTML level
										/>
										<div className={`text-sm  mt-1 ${formData.message.length > 159 ? "text-red-400" : "text-gray-600"}`}>
											{formData.message.length}/{maxLength}
										</div>
									</div>





									{errors.message && <span className='text-red-500'>{errors.message}</span>}
									{apiError && <span className='text-red-500'>{apiError}</span>}
								</div>
								<div className='w-full justify-end flex mt-6'>
									<button type="submit" className='bg-blue-500 rounded p-2 text-sm px-4' onClick={handleSubmit}>Sačuvaj</button>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	);
}
