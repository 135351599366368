// src/GoogleCallback.js

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from '../axios';

function GoogleCallback() {
    const [loading, setLoading] = useState(true);
    const fetchCalled = useRef(false);
    const { setUser } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    
  



    useEffect(() => {

        const state = queryParams.get('state');
        const decodedState = JSON.parse(decodeURIComponent(state));
        const type = decodedState.type;
        if (!fetchCalled.current) {
            fetchCalled.current = true;
            axios.get(`/auth/callback${location.search}&type=${encodeURIComponent(type)}`)
                .then((response) => {
                    setLoading(false);
                    console.log(response.data.user);
                    setUser(response.data.user);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response && error.response.status === 404) {
                        // Pass the error message to the /login page
                        navigate('/login', { state: { ApiError: 'User not found. Please register.' } });
                    } else {
                        console.error("Error during Google login:", error);
                        navigate('/login', { state: { ApiError: 'An unexpected error occurred.' } });
                    }
                });
        }
    }, [location.search, setUser]);

    if (loading) {
        return <DisplayLoading />;
    } else {
        return <DisplayData />;
    }
}

function DisplayLoading() {
    return <div>Loading....</div>;
}

function DisplayData() {
    return <div>Redirecting...</div>;
}

export default GoogleCallback;
