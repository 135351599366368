import React, { useEffect } from 'react';

function Toast({ message, isOpen, setIsOpen, isToastError }) {
    useEffect(() => {
        let timer;
        if (isOpen) {
            timer = setTimeout(() => {
                setIsOpen(false);
            }, 3500); // Close toast after 5 seconds
        }

        return () => {
            clearTimeout(timer); // Cleanup the timer when the component unmounts or when isOpen changes
        };
    }, [isOpen, setIsOpen]);

    const closeToast = () => {
        setIsOpen(false);
    };

    return (
        <div className="absolute top-2 left-1/2 transform -translate-x-1/2 w-full flex items-center justify-center z-[999]">
            {isOpen && (
                <button 
                    type="button" 
                    onClick={closeToast} 
                    className={`fixed top-4 z-50 rounded-md px-8 py-2 text-white ${isOpen ? 'animate-fade-in' : 'animate-fade-out'} ${isToastError ? "bg-red-500 " : "bg-blue-500"}`}
                >
                    <div className="flex items-center space-x-2">
                        <span className="text-3xl"></span>
                        <p className="font-semibold">{message}</p>
                    </div>
                </button>
            )}
        </div>
    );
}

export default Toast;
