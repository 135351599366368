import React, { useState } from 'react';
import axios from 'axios';
import settings from '../../settings';
import { Helmet } from 'react-helmet-async';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setMessage("Poslali smo vam link za resetovanje putem emaila.");
            const response = await axios.post(settings.backendUrl + '/api/forgot-password', { email });
            //setMessage(response.data.status);


        } catch (error) {
            setMessage(error.response.data.email[0]);
        }
    };

    return (
        <div className='w-full  flex min-h-screen bg-purplebg items-center justify-center'>
            <Helmet>
                <title>NapomeniMe - Resetuj lozinku</title>
            </Helmet>
            <div className='bg-uicolor w-full  rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 flex items-center mx-4'>
                <div className='  w-full items-center flex flex-col shadow-lg bg-gray-100 border'>
                    <h1 className='text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl  mt-6'>Zaboravljena lozinka</h1>
                    <form onSubmit={handleSubmit} className='flex flex-col w-full px-6'>
                        <div className='w-full '>
                            <label
                                htmlFor="email"

                                className="block mb-2 mt-4 text-sm font-medium text-black ">
                                Vaš Email
                            </label>
                            <input
                                className='sm:text-sm rounded-lg block w-full p-2.5 bg-gray-200 text-black focus:border-blue-500 bg-insideui'
                                type="email"
                                value={email}
                                placeholder="name@domain.com"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className=' mt-6  mb-5 bg-blue-600  py-2  w-full h-full  font-semibold  rounded-lg'>Pošalji link za resetovanje</button>
                    </form>
                    {message && <p className='mb-6 text-black'>{message}</p>}
                </div>

            </div>
        </div>
    );
}

export default ForgotPassword;
