import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import Drawer from '../components/Drawer';
import { Helmet } from 'react-helmet-async';
import ScheduleView from '../components/ScheduleView';
import EditTermin from '../components/EditTermin';
import Toast from '../components/Toast';
import axios from '../axios';


export default function Racun() {
    const { user } = useAuth();
    const [toastMessage, setToastMessage] = React.useState('');
    const [isToastOpen, setIsToastOpen] = React.useState(false);
    const [isToastError, setIsToastError] = React.useState();
    const [userData, setUserData] = React.useState();



    const dohvatiUserData = async () => {
		try {
		  const response = await axios.get('/userData');
		  setUserData(response.data)
		  console.log(response.data)
	
		} catch (error) {
		  console.error( error);
		}
	  };
	
	  React.useEffect(() => {
		dohvatiUserData();
	  }, []);

    return (
        <div className='flex-grow flex flex-col' >

            <Helmet>
                <title>Račun</title>
            </Helmet>
            <Toast message={toastMessage} isOpen={isToastOpen} setIsOpen={setIsToastOpen} isToastError={isToastError} />
            <div className='flex-grow flex flex-col text-black'>
                <div class="bg-gray-100 p-6 rounded-lg shadow-lg border">
                    <div className='mb-6'>
                        <h2 className='text-2xl font-semibold '>Vaše stanje računa:</h2>
                        <p className='text-black text-lg '>Trenutno imate <strong>{userData ? userData.smsKrediti : ""} SMS kredita</strong> na raspolaganju.</p>
                        <p className='text-black text-lg'>Vaša pretplata na softver ističe <strong>{userData ? userData.planExpireDate : ""}</strong></p>
                    </div>

                    <h2 class="text-2xl font-semibold mb-4">Cijenovnik:</h2>
                    <ul class="list-disc pl-5 mb-4">
                        <li class="mb-2"><strong>Mjesečna pretplata za softver:</strong> 50 KM</li>
                        <li><strong>Cijena SMS poruka:</strong> 0,15 KM po poruci</li>
                    </ul>

                    <h2 class="text-2xl font-semibold mb-4">Upute za uplatu:</h2>
                    <p class="mb-4">1. <strong>Mjesečna pretplata:</strong> Uplatite 50 KM na naš bankovni račun za korištenje softvera. Ova uplata pokriva pristup svim funkcionalnostima softvera za jedan mjesec.</p>
                    <p class="mb-4">2. <strong>SMS Krediti:</strong> SMS kredite možete dopuniti prema vlastitim potrebama. Cijena je 0,15 KM po SMS poruci. Nakon uplate za SMS kredite, dodajemo ih na vaš račun prema iznosu uplaćenom za SMS kredite. SMS krediti ne mogu isteći.</p>

                    <h2 class="text-2xl font-semibold mb-4">Detalji za uplatu:</h2>
                    <div class="bg-gray-50 p-4 rounded-lg border border-gray-300 mb-4">
                        <p class="mb-2"><strong>Bankovni račun:</strong> BA391613000122427053</p>
                        <p class="mb-2"><strong>Naziv primatelja:</strong> Vladimir Stojanović</p>
                        <p><strong>Poziv na broj:</strong> +38766774473</p>
                    </div>

                    <p class="mb-4">Molimo vas da nakon uplate pošaljete potvrdu o uplati na našu e-mail adresu <strong>kontakt@NapomeniMe.com</strong> kako bismo mogli ažurirati vaš račun sa novim SMS kreditima.</p>
                    <p>Ako imate bilo kakvih pitanja ili trebate pomoć oko uplate, slobodno nas kontaktirajte.</p>
                </div>

            </div>
        </div>
    );
}