import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from '../axios';
import { useAuth } from '../contexts/AuthContext';

export default function Drawer() {
    const [isOpened, setIsOpened] = useState();
    const [isMobile, setIsMobile] = useState();
    const { user, setUser } = useAuth();
    const email = user.email;
    const truncatedEmail = email.length > 16 ? email.substring(0, 16) + '...' : email;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setIsOpened(false);
                setIsMobile(true)
            } else {
                setIsOpened(true);
                setIsMobile(false)
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };
    function CloseMenuOnClick() {
        if (isMobile === true) {
            setIsOpened(false)
        }
    }

    // logout user
    const handleLogout = async () => {
        try {
            const resp = await axios.post('/logout');
            if (resp.status === 200) {
                localStorage.removeItem('user');
                window.location.href = '/';
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className=''>
            <button onClick={() => setIsOpened(prevState => !prevState)} className={`group  focus:outline-none lg:hidden  z-[11] absolute p-3 mt-2 ml-2 ${isOpened ? "" : "bg-uicolor"} rounded-lg`}>
                <div className="grid justify-items-center gap-1.5">
                    <span className={`h-1 w-8 rounded-full bg-black transition-transform transform ${isOpened ? 'rotate-45 translate-y-2.5' : ''}`}></span>
                    <span className={`h-1 w-8 rounded-full bg-black transition-transform transform ${isOpened ? 'scale-x-0' : ''}`}></span>
                    <span className={`h-1 w-8 rounded-full bg-black transition-transform transform ${isOpened ? '-rotate-45 -translate-y-2.5' : ''}`}></span>
                </div>
            </button>


            <div className=" relative h-screen ">

                <div
                    className={` top-0 left-0 w-60 absolute  bg-[#F6F4F7] h-full transform transition-transform ${isOpened ? 'translate-x-0' : '-translate-x-full'
                        }`}
                >
                    <div className='p-4'>
                        <div className='w-full flex items-center ml-12 lg:ml-0 '>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-blue-500">
                                <path fillRule="evenodd" d="M4.804 21.644A6.707 6.707 0 0 0 6 21.75a6.721 6.721 0 0 0 3.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 0 1-.814 1.686.75.75 0 0 0 .44 1.223ZM8.25 10.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM10.875 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z" clipRule="evenodd" />
                            </svg>

                            <h1 className='text-xl font-semibold   text-gray-900 '>NapomeniMe</h1>
                        </div>

                        <div>
                            <ul className=' mt-16'>
                                <Link onClick={CloseMenuOnClick} to="/kalendar" className={`${isActive('/kalendar') ? "text-gray-200  shadow-sm bg-white" : "text-graytext"} font-bold h-12 flex items-center  text-center  w-full rounded `}>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 ml-3  ${isActive('/kalendar') ? "stroke-blue-500" : "stroke-gray-700"}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                    </svg>

                                    <a href="" className='ml-4 text-sm text-black'>Kalendar</a>
                                </Link>
                                <Link onClick={CloseMenuOnClick} to="/dodajtermin" className={`${isActive('/dodajtermin') ? "text-gray-200  shadow-sm bg-white" : "text-graytext"} font-bold h-12 flex items-center  text-center  w-full rounded `}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 ml-3  ${isActive('/dodajtermin') ? "stroke-blue-500" : "stroke-gray-700"}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>

                                    <a href="" className='ml-4 text-sm text-black'>Dodaj termin</a>
                                </Link>

                                <Link onClick={CloseMenuOnClick} to="/templejti" className={`${isActive('/templejti') ? "text-gray-200  shadow-sm bg-white" : "text-graytext"} font-bold h-12 flex items-center  text-center  w-full rounded `}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  className={`size-6 ml-3  ${isActive('/templejti') ? "stroke-blue-500" : "stroke-gray-700"}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                                    </svg>


                                    <a href="" className='ml-4 text-sm text-black'>Templejti</a>
                                </Link>


                                <Link onClick={CloseMenuOnClick} to="/racun" className={`${isActive('/racun') ? "text-gray-200  shadow-sm bg-white" : "text-graytext"} font-bold h-12 flex items-center  text-center  w-full rounded `}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 ml-3  ${isActive('/racun') ? "stroke-blue-500" : "stroke-gray-700"}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>

                                    <a href="" className='ml-4 text-sm text-black'>Račun</a>
                                </Link>


                                <Link onClick={CloseMenuOnClick} to="/kontakt" className={`${isActive('/kontakt') ? "text-gray-200  shadow-sm bg-white" : "text-graytext"} font-bold h-12 flex items-center  text-center  w-full rounded `}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 ml-3  ${isActive('/kontakt') ? "stroke-blue-500" : "stroke-gray-700"}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                    </svg>

                                    <a href="" className='ml-4 text-sm text-black'>Pomoć</a>
                                </Link>





                            </ul>
                        </div>
                    </div>
                    <div className='bottom-0 fixed w-full  '>
                        <div className='w-[90%] mb-8 justify-center space-x-4 flex items-center relative'>
                            <span className='text-graytext font-semibold text-sm text-black'>{truncatedEmail}</span>
                            <button className="relative group" onClick={handleLogout}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6  stroke-black">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                                </svg>
                                <div className="absolute bottom-full mb-2 w-max px-2 py-2 font-bold bg-gray-200 text-black text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    Odjavi se
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
