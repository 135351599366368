import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import router from './router';

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<AuthProvider>
		<HelmetProvider>
			<RouterProvider router={router} />
		</HelmetProvider>
		</AuthProvider>
	</React.StrictMode>
);
