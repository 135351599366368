// src/components/Accordion.js
import { useState } from 'react';

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full max-w-[1000px]">
      <button
        className="w-full text-left py-3 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-lg ${isOpen ? "text-blue-950" : "text-black"} tracking-wide font-semibold`}>{question}</span>
        <svg
          className={`size-4 transform transition-transform duration-100 text-black ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div className={`transition-all duration-100 overflow-hidden ${isOpen ? 'max-h-[500px]' : 'max-h-0'}`}>
        <div className="pb-4">
          <p className="text-gray-800 font-normal text-base">{answer}</p>
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ children }) => {
  return (
    <div className="my-[50px] flex flex-col items-center px-4">
      <div className='max-w-[1000px] items-center justify-center flex flex-col'>
        <h2 className='font-bold text-4xl bg-gradient-to-r text-black'>Česta pitanja</h2>
        <p className='text-center mt-2 text-base text-black mx-auto'> Odgovori na česta pitanja o NapomeniMe.com Ako imate bilo kakva dodatna pitanja, molimo vas da nas <a className='text-blue-600 underline' href="/kontakt">kontaktirate</a></p>
      </div>
      <div className='w-full max-w-[1000px] rounded-lg p-4 mt-8 pb-6 bg-uicolor border-2 bg-white shadow-xl'>
        {children}
      </div>
    </div>
  );
};

export { Accordion, AccordionItem };
