import React, { useState, useEffect, useRef } from 'react';

const DatePicker = ({selectedDate, setSelectedDate}) => {
  const today = new Date();

  const [showCalendar, setShowCalendar] = useState(false);

  const daysOfWeek = ['Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su', 'Ne'];
  const months = ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Juni', 'Juli', 'August', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'];

  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const datepickerRef = useRef(null);

  const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const handleDateClick = (day) => {
    const selected = new Date(currentYear, currentMonth, day);
    const date = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleClickOutside = (event) => {
    if (datepickerRef.current && !datepickerRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const daysInMonth = getDaysInMonth(currentMonth, currentYear);
  const firstDayOfMonth = (new Date(currentYear, currentMonth, 1).getDay() + 6) % 7;

  return (
    <div className="relative" ref={datepickerRef}>
      <input
        type="text"
        value={selectedDate}
        onClick={() => setShowCalendar(!showCalendar)}
        readOnly
        className="border p-2 text-center cursor-pointer bg-slate-100 w-[120px] text-black"
      />
      {showCalendar && (
        <div className="absolute w-[220px]   sm:w-[300px] top-12 left-0 bg-white border rounded-lg shadow-lg z-10 text-black">
          <div className="flex justify-between items-center p-2 bg-gray-100">
            <button onClick={handlePrevMonth} className="text-gray-700">&lt;</button>
            <span>{`${months[currentMonth]} ${currentYear}`}</span>
            <button onClick={handleNextMonth} className="text-gray-700">&gt;</button>
          </div>
          <div className="grid grid-cols-7 gap-1 p-2">
            {daysOfWeek.map((day) => (
              <div key={day} className="text-center font-bold text-gray-500">{day}</div>
            ))}
            {Array.from({ length: firstDayOfMonth }).map((_, idx) => (
              <div key={`empty-${idx}`} className="p-2 text-center" />
            ))}
            {[...Array(daysInMonth).keys()].map((day) => {
              const currentDate = new Date(currentYear, currentMonth, day + 1);
              const isToday = currentDate.toDateString() === today.toDateString();
              const isDisabled = currentDate < today && !isToday;

              return (
                <button
                  key={day}
                  onClick={() => !isDisabled && handleDateClick(day + 1)}
                  className={`p-2 text-center cursor-pointer ${
                    isDisabled ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'
                  } ${isToday ? '' : ''}`}
                  disabled={isDisabled}
                >
                  {day + 1}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
