import React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import axios from '../../axios';
import { useAuth } from '../../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import SignIn from '../../components/GoogleSignBtn';

export default function Login() {
	const { setUser, csrfToken } = useAuth();
	const [error, setError] = React.useState(null);

	const location = useLocation();
	React.useEffect(() => {
		if (location.state && location.state.ApiError) {
			setError(location.state.ApiError);
		}
	}, [location.state]);
	// login user
	const handleSubmit = async (e) => {
		e.preventDefault();
		const { email, password } = e.target.elements;
		const body = {
			email: email.value,
			password: password.value,
		};
		await csrfToken();
		try {
			const resp = await axios.post('/login', body);
			if (resp.status === 200) {
				setUser(resp.data.user);
				return <Navigate to="/profile" />;
			}
		} catch (error) {
			if (error.response.status === 401) {
				setError(error.response.data.message);
			}
		}
	};

	return (
		<section className="bg-purplebg">
			<Helmet>
				<title>NapomeniMe - Prijavi se</title>
			</Helmet>
			<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">

				<div className="w-full  rounded-lg   md:mt-0 sm:max-w-md xl:p-0  bg-gray-100 border shadow-lg">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<div className='w-full flex items-center justify-center flex-col'>
							<h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl ">
								Prijavite se na svoj račun
							</h1>
							<div className='mt-8 hidden'><SignIn BtnText={"Login with Google"} isLogin={true} /></div>
							<div className='w-full  items-center justify-between gap-x-4 mt-8 hidden'>
								<div className='w-full border-t'></div>
								<div>Or</div>
								<div className='w-full border-t'></div>
							</div>

						</div>
						{error && (

							<div
								className="flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  dark:text-red-400 dark:border-red-800"
								role="alert">
								<svg
									aria-hidden="true"
									className="flex-shrink-0 inline w-5 h-5 mr-3"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
										clipRule="evenodd"></path>
								</svg>
								<span className="sr-only">Info</span>
								<div>{error}</div>
							</div>
						)}

						<form
							className="space-y-4 md:space-y-6 "
							action="#"
							method="post"
							onSubmit={handleSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block mb-2 mt-4 text-sm font-medium text-black">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className=" sm:text-sm rounded-lg block w-full p-2.5  focus:border-blue-500 bg-gray-200 text-black"
									placeholder="name@domain.com"
									required
								/>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block mb-2 text-sm font-medium text-black">
									Lozinka
								</label>
								<input
									type="password"
									name="password"
									id="password"
									placeholder="••••••••"
									className="sm:text-sm rounded-lg block w-full p-2.5  focus:border-blue-500 bg-gray-200 text-black"
									required
								/>
							</div>

							<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								Zaboravili ste lozinku?{' '}
								<Link
									to="/forgot/password"
									className="font-medium text-primary-600 hover:underline dark:text-primary-500 text-blue-400">
									Resetujte lozinku
								</Link>

							</p>

							<button type="submit" className='bg-blue-600  py-2  w-full h-full  font-semibold  rounded-lg ' href="/dashboard">
								Prijavi se
							</button>
							<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								Nemate još račun? {' '}
								<Link
									to="/register"
									className="font-medium text-primary-600 hover:underline dark:text-primary-500 text-blue-400">
									Registrujte se
								</Link>

							</p>

						</form>

					</div>
				</div>
			</div>
		</section>
	);
}