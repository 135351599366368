import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from '../../axios';
import { useAuth } from '../../contexts/AuthContext';
import SignIn from '../../components/GoogleSignBtn';
import { Helmet } from 'react-helmet-async';

export default function Register() {
	const { setUser } = useAuth();
	const [nameError, setNameError] = React.useState('');
	const [emailError, setEmailError] = React.useState('');
	const [passwordError, setPasswordError] = React.useState('');
	// register user
	const handleSubmit = async (e) => {
		e.preventDefault();
		const { name, email, password, cpassword, poduzece } = e.target.elements;
		const body = {
			name: null,
			email: email.value,
			password: password.value,
			password_confirmation: cpassword.value,
			poduzece: poduzece.value,
		};
		try {
			const resp = await axios.post('/register', body);
		
			if (resp.status === 200) {
				setUser(resp.data.user);
				return <Navigate to="/profile" />;
			}
		} catch (error) {
			console.log(error)
			if (error.response.status === 422) {
				//console.log(error.response.data.errors);
				if (error.response.data.errors.name) {
					setNameError(error.response.data.errors.name[0]);
				} else {
					setNameError('');
				}
				if (error.response.data.errors.email) {
					setEmailError(error.response.data.errors.email[0]);
				} else {
					setEmailError('');
				}
				if (error.response.data.errors.password) {
					setPasswordError(error.response.data.errors.password[0]);
				} else {
					setPasswordError('');
				}
			}
		}
	};

	return (
		<section className="">
			<Helmet>
				<title>NapomeniMe - Napravi nalog</title>
			</Helmet>
			<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">

				<div className="w-full bg-uicolor rounded-lg  md:mt-0 sm:max-w-md xl:p-0 bg-gray-100 border shadow-lg text-black">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
					<div className='w-full flex items-center justify-center flex-col'>
						<h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl ">
						Napravi nalog
						</h1>
						<div className='mt-8 hidden'><SignIn BtnText={"Register with Google"} isLogin={false} /></div>
						<div className='w-full  items-center justify-between gap-x-4 mt-8 hidden'>
							<div className='w-full border-t'></div>
							<div>Or</div>
							<div className='w-full border-t'></div>
						</div>

					</div>

						<form
							className="space-y-4 md:space-y-6"
							action="#"
							method="post"
							onSubmit={handleSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block mb-2 text-sm font-medium text-gray-900 ">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className=" text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-200 dark:focus:border-blue-500"
									placeholder="name@domain.com"
									required
								/>
								{emailError && (
									<p className="text-sm text-red-600">{emailError}</p>
								)}
							</div>
							<div>
								<label
									htmlFor="poduzece"
									className="block mb-2 text-sm font-medium text-gray-900 ">
									Naziv poduzeća
								</label>
								<input
									type="poduzece"
									name="poduzece"
									id="poduzece"
									className=" text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-200 dark:focus:border-blue-500"
									placeholder="Naziv poduzeća"
									required
								/>

							</div>
							<div>
								<label
									htmlFor="password"
									className="block mb-2 text-sm font-medium text-gray-900 ">
									Lozinka
								</label>
								<input
									type="password"
									name="password"
									id="password"
									placeholder="••••••••"
									className=" text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-200 dark:focus:border-blue-500"
									required
								/>
								{passwordError && (
									<p className="text-sm text-red-600">{passwordError}</p>
								)}
							</div>
							<div>
								<label
									htmlFor="cpassword"
									className="block mb-2 text-sm font-medium text-gray-900 ">
									Potvrda lozinke
								</label>
								<input
									type="password"
									name="cpassword"
									id="cpassword"
									placeholder="••••••••"
									className=" text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-200 dark:focus:border-blue-500"
									required
								/>
							</div>

							<button
								type="submit"
								className="w-full text-white bg-blue-600 font-semibold focus:ring-4 focus:outline-none focus:ring-primary-300  rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
								Napravi nalog
							</button>
							<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								Already have an account?{' '}
								<Link
									to="/login"
									className="font-medium text-blue-400">
									Login here
								</Link>
							</p>
						</form>
						
					</div>
				</div>
			</div>
		</section>
	);
}
