import { createBrowserRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'

import LandingPage from './pages/LandingPage';

import ProtectedLayout from './components/ProtectedLayout';
import GuestLayout from './components/GuestLayout';
import GoogleCallback from './pages/GoogleCallback';
import MainLayout from './components/MainLayout';
import Kalendar from './pages/Kalendar';
import Dodajtermin from './pages/Dodajtermin';
import NotFound from './pages/NotFound';
import Kontakt from './pages/Kontakt';
import Cijenovnik from './pages/Cijenovnik';
import Templejti from './pages/Templejti';
import Racun from './pages/Racun';

const router = createBrowserRouter([
	{
		path: '/',
		element: <LandingPage />,

	},
	{
		path: '/kontakt',
		element: <Kontakt />
	},
	{
		path: '/cijenovnik',
		element: <Cijenovnik />
	},
	{

		element: <GuestLayout />,
		children: [

			{
				path: '/login',
				element: <Login />,
			},
			{
				path: '/register',
				element: <Register />,
			},
			{
				path: '/auth/google',
				element: <GoogleCallback />,
			},
			{
				path: '/forgot/password',
				element: <ForgotPassword />,
			},
			{
				path: '/reset-password',
				element: <ResetPassword />,
			},



		],
	},
	{

		element: <ProtectedLayout />,
		children: [
			{
				element: <MainLayout />,
				children: [
					{
						path: '/dodajtermin',
						element: <Dodajtermin />,
					},
					{
						path: '/kalendar',
						element: <Kalendar />,
					},
					{
						path: '/racun',
						element: <Racun />,
					},
					{
						path: '/templejti',
						element: <Templejti />,
					},

				],
			},
		],
	},
	{
		path: '*', // This route will catch all undefined routes
		element: <NotFound />,
	},

]);

export default router;