import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import settings from '../../settings';
import { Helmet } from 'react-helmet-async';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(settings.backendUrl + '/api/reset-password', {
                token,
                email,
                password,
                password_confirmation: passwordConfirmation,
            });
            setMessage(response.data.status);
            setError('');
        } catch (error) {
            console.log(error.response); // Log the entire response for debugging
            setMessage('');
            if (error.response && error.response.data) {
                // Check for specific error messages and handle them
                setError(error.response.data.email ? error.response.data.email[0] : 'An error occurred');
            } else {
                setError('An unexpected error occurred');
            }
        }
    };

    return (
        <div className='w-full  flex min-h-screen bg-purplebg items-center justify-center'>
            <Helmet>
                <title>NapomeniMe - Resetuj lozinku</title>
            </Helmet>
            <div className='bg-uicolor w-full  rounded-lg   md:mt-0 sm:max-w-md xl:p-0 flex items-center mx-4 bg-gray-100 shadow-lg  border '>
                <div className='  w-full items-center flex flex-col'>
                    <h1 className='text-xl font-semibold leading-tight tracking-tight text-black md:text-2xl  mt-6'>Reset Password</h1>
                    <form onSubmit={handleSubmit} className='flex flex-col w-full px-6'>
                        <div>
                            <label
                                className="block mb-2 mt-4 text-sm font-medium text-black ">
                                Nova lozinka
                            </label>
                            <input
                                className='sm:text-sm rounded-lg block w-full p-2.5  focus:border-blue-500 bg-gray-200'
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="New Password"
                            />
                        </div>
                        <div>
                            <label
                                className="block mb-2 mt-4 text-sm font-medium text-black ">
                                Potvrdi lozinku
                            </label>
                            <input
                                className='sm:text-sm rounded-lg block w-full p-2.5  focus:border-blue-500 bg-gray-200'
                                type="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                required
                                placeholder="Confirm New Password"
                            />
                        </div>

                        <button type="submit" className=' mt-6  mb-5 bg-blue-600  py-2  w-full h-full  font-semibold  rounded-lg'>Reset Password</button>
                    </form>
                    {message && <p className='text-black'>{message}</p>}
                    {error && <p className='text-red-500'>{error}</p>}
                    <div className='h-6'></div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
