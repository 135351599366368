import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import LandingNav from '../components/LandingNav';
import Footer from '../components/Footer';
import { Accordion, AccordionItem } from '../components/Accordion';
import LandingDrawer from '../components/LandingDrawer';
import axios from '../axios';

export default function Dashboard() {
	const { user } = useAuth();


	const [isVisitorGuest, setIsVisitorGuest] = React.useState(true)
	React.useEffect(() => {
		(async () => {
			try {
				const resp = await axios.get('/user');
				if (resp.status === 200) {
					setIsVisitorGuest(false);
				}
			} catch (error) {
				if (401 === 401) {


				}
			}
		})();
	}, []);

	return (
		<div className='relative flex-grow flex flex-col  '>
			<Helmet>
				<title>NapomeniMe - program za SMS Podsjetnike i Zakazivanje Termina</title>
				<meta name="description" content="NapomeniMe je softver za zakazivanje termina i automatsko slanje SMS podsjetnika. Smanjite propuštene termine i poboljšajte efikasnost poslovanja." />
				<meta name="keywords" content="zakazivanje termina, SMS podsjetnici, automatsko slanje poruka, smanjenje propuštenih termina, NapomeniMe, poslovni softver" />
				<meta name="robots" content="index, follow" />
				<meta property="og:url" content="https://napomenime.com" />
				<meta property="og:type" content="website" />
				<meta name="twitter:title" content="NapomeniMe - Automatski SMS Podsjetnici za Termine" />
				<meta name="twitter:description" content="Smanjite propuštene termine i poboljšajte poslovanje uz automatske SMS podsjetnike za klijente." />
			</Helmet>

			<div>
				<div
					className='absolute top-0 left-0 w-full min-h-screen bg-no-repeat bg-top sm:mt-0 mt-[10px]'
					style={{
						backgroundImage: "url('/images/webbg.png')",
						backgroundSize: 'cover', // Ensures the image covers the entire container
						backgroundAttachment: 'scroll', // Makes the background scroll with the content
						zIndex: -1, // Ensures the background image stays behind other content
					}}
				>
				</div>

				<div className=''>
					<LandingNav isVisitorGuest={isVisitorGuest} />
					<div className='flex items-start justify-center  px-4 sm:px-12 md:flex-row flex-col gap-x-6'>
						<div className='md:max-w-[600px] '>
							<h1 className='font-bold text-4xl text-black md:mt-[120px] mt-[40px]'>Smanjite propuštene termine uz SMS podsjetnike za preduzeća.</h1>
							<p className='text-blue-950 mt-2  leading-8'>NapomeniMe je softversko rješenje osmišljeno za poliklinike i ostale kompanije koje pružaju usluge s klijentima i žele smanjiti broj propuštenih termina. Uz NapomeniMe, vaše osoblje može lako upravljati rasporedom termina i automatski slati podsjetnike putem SMS poruka.</p>
							<div className='mt-8'>
								<a href='/register' className='bg-blue-950 rounded-lg p-4 text-white  px-12'>Napravi nalog</a>

							</div>
						</div>
						<div className='w-full md:w-1/2 items-center justify-center flex  mt-[80px] sm:mt-[40px]'>

							<img src="/images/iphonesmsmockup.png" alt="iphone reminder sms mockup" className='max-w-[400px] w-full' />
							<div className="mockup-phone hidden">
								<div className="camera"></div>
								<div className="display">
									<div className="artboard artboard-demo phone-1">
										<div className='h-full w-full bg-white'>
											<div className='mt-24 mx-2 items-center justify-center flex flex-col'>
												<span className='text-center w-full'>Danas u 13:00</span>
												<div className=' bg-blue-500 p-4 rounded-lg mt-2'><span className='text-white'>Pozdrav Vladimire, vas zakazani termin je u 14:00 u poliklinici Musa. </span></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className=' mt-12 sm:mt-[250px]  w-full items-center justify-center flex flex-col'>
				<h2 className='text-black font-bold text-3xl'>Zašto SMS podsjetnici?</h2>
				<div className='w-full items-center md:items-start justify-center flex md:flex-row flex-col  mt-8 md:space-x-8'>

					<div className='flex 1400:space-x-8 flex-col 1400:flex-row '>
						<div className='w-[320px] text-black bg-gray-100 h-[220px] items-center justify-center  p-4 rounded-lg'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-blue-400">
								<path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
							</svg>

							<h3 className='font-bold text-xl'>Automatski SMS podsjetnici</h3>
							<p className='text-gray-600'>Kada se termin zakaže, sistem automatski šalje klijentima podsjetnike prije zakazanog termina, smanjujući mogućnost zaborava.</p>
						</div>

						<div className='w-[320px] text-black bg-gray-100 h-[220px] items-center justify-center  p-4 rounded-lg mt-4 1400:mt-0'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-blue-400">
								<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
							</svg>

							<h3 className='font-bold text-xl'>Ušteda vremena</h3>
							<p className='text-gray-600'>Eliminišite potrebu za ručnim slanjem podsjetnika, čime osoblje može posvetiti više vremena ključnim zadacima.</p>
						</div>
					</div>

					<div className='flex 1400:space-x-8 flex-col 1400:flex-row mt-4 md:mt-0 '>
						<div className='w-[320px] text-black bg-gray-100 h-[220px] items-center justify-center  p-4 rounded-lg'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-blue-400">
								<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
							</svg>


							<h3 className='font-bold text-xl'>Smanjenje propuštenih termina</h3>
							<p className='text-gray-600'>Redovnim podsjećanjem klijenata na zakazane termine, smanjuje se broj nepojavljivanja, što direktno doprinosi povećanju prihoda.</p>
						</div>

						<div className='w-[320px] text-black bg-gray-100 h-[220px] items-center justify-center  p-4 rounded-lg mt-4 1400:mt-0'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-blue-400">
								<path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
							</svg>



							<h3 className='font-bold text-xl'>Jednostavna integracija</h3>
							<p className='text-gray-600'>Naš softver se lako integriše u postojeće poslovne procese poliklinika i drugih preduzeća koja pružaju usluge na zakazivanje.</p>
						</div>
					</div>
				</div>


				<div className='w-full items-center justify-center flex mt-[150px] flex-col p-2'>
					<h2 className='text-black font-bold text-3xl  text-center'>Preglednost i Jednostavnost</h2>
					<p className='text-gray-700 max-w-[650px] text-center '>Naš softver je dizajniran s fokusom na jednostavnost i preglednost. Bilo da prvi put koristite softver ili imate iskustva s njim, lako ćete se snaći! Upravljajte terminima brzo i efikasno, uz sve informacije na dohvat ruke.</p>
					<img src="/images/demodashboard.png" alt="screenshot of napomenime  dashboard" className='md:max-w-[70%] max-w-[95%]  rounded-lg border-2 shadow-xl mt-6' />



					<div className='bg-gray-100 w-full flex items-center justify-center mt-12  flex-col'>
						<div id="FAQ" className='my-12'>
							<Accordion>
								<AccordionItem question="U kojim državama NapomeniMe trenutno radi?" answer="Trenutno NapomeniMe radi samo u Bosni i Hercegovini. Međutim, uskoro planiramo proširiti naše usluge na Hrvatsku, Srbiju i Crnu Goru." />
								<AccordionItem question="Kako funkcioniše SMS dopuna na NapomeniMe?" answer="NapomeniMe koristi unaprijed plaćene SMS kredite. Možete dopuniti svoj račun i zatim slati SMS podsjetnike klijentima. Cijenovnik za dopunu SMS kredita možete pronaći u našem cjenovniku. Također, prilikom registracije dobijate 5 KM u  SMS kreditima, kako biste mogli isprobati sve naše funkcionalnosti i vidjeti kako NapomeniMe može pomoći vašem poslovanju." />
								<AccordionItem question="Da li NapomeniMe radi na telefonu?" answer="Da, NapomeniMe radi na svakom telefonu koji ima internet preglednik. Sve što treba da uradite je da otvorite web stranicu napomenime.com na svom telefonu i odmah možete početi koristiti uslugu." />
								<AccordionItem question="Da li je potrebno instalirati NapomeniMe na računar da bi radio?" answer="Ne, NapomeniMe je web aplikacija koja radi na svakom uređaju koji ima internet preglednik. Nema potrebe za instalacijom, možete koristiti NapomeniMe direktno sa bilo kog uređaja, uključujući računar, tablet ili telefon." />
								<AccordionItem question="Da li mogu prilagoditi tekst podsjetnika?" answer="Naravno, NapomeniMe vam omogućava da prilagodite tekst SMS podsjetnika prema vašim potrebama. Možete personalizirati poruke za svakog klijenta ili koristiti predložene šablone." />
							</Accordion>
						</div>


					</div>
					<div className='items-center justify-center flex flex-col  my-[150px] '>
						<h1 className='text-black sm:text-5xl text-4xl font-semibold text-center mx-4'>Napravi nalog na NapomeniMe</h1>
						<a href='/register' className='bg-blue-950 rounded-lg p-4 w-[95%] sm:w-[400px] mt-6 text-center'>Napravi nalog</a>
						<p className='text-gray-900 text-lg font-semibold text-center'>5 KM besplatno u SMS kreditima :)</p>
					</div>



				</div>

			</div>

			<Footer />





		</div>
	);
}
