import Axios from 'axios';
import settings from './settings';
const axios = Axios.create({
	baseURL: settings.backendUrl + "/api",
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
	},
});


export default axios;