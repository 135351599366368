import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import Drawer from '../components/Drawer';
import { Helmet } from 'react-helmet-async';
import ScheduleView from '../components/ScheduleView';
import EditTermin from '../components/EditTermin';
import Toast from '../components/Toast';
import axios from '../axios';


export default function Kalendar() {
	const { user } = useAuth();
	const [toastMessage, setToastMessage] = React.useState('');
	const [isToastOpen, setIsToastOpen] = React.useState(false);
	const [isToastError, setIsToastError] = React.useState();
	const [userData, setUserData] = React.useState();



	const dohvatiUserData = async () => {
		try {
		  const response = await axios.get('/userData');
		  setUserData(response.data)
		  console.log(response.data)
	
		} catch (error) {
		  console.error( error);
		}
	  };
	
	  React.useEffect(() => {
		dohvatiUserData();
	  }, []);

	return (
		<div className='flex-grow flex flex-col' >

			<Helmet>
				<title>Kalendar</title>
			</Helmet>
			<Toast message={toastMessage} isOpen={isToastOpen} setIsOpen={setIsToastOpen} isToastError={isToastError} />
			<div className='flex-grow flex flex-col'>
				<div className='mb-4'>
					<span className='text-black'>Trenutno imate {userData ? userData.smsKrediti : ""} SMS-ova.</span>
					<a href="/racun" className='text-blue-500 underline ml-2'>Dopuni SMS kredite.</a>
				</div>
				<ScheduleView toastMessage={toastMessage} isToastOpen={isToastOpen} setIsToastOpen={setIsToastOpen} isToastError={isToastError} setIsToastError={setIsToastError} setToastMessage={setToastMessage} />
			</div>
		</div>
	);
}