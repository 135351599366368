import React, { useState, useEffect } from "react";
import { format, addWeeks, subWeeks, startOfWeek, addDays } from "date-fns";
import { bs } from "date-fns/locale";
import axios from '../axios';
import EditTermin from "./EditTermin";

import ConfirmModal from './ConfirmModal';
const PregledRasporeda = ({ toastMessage, isToastOpen, isToastError, setIsToastError, setIsToastOpen, setToastMessage }) => {
  const [trenutnaSedmica, setTrenutnaSedmica] = useState(new Date());
  const [termini, setTermini] = useState([]);
  const [isOpened, setIsOpened] = React.useState(false);

  const [selectedOpenTermin, setSelectedOpenTermin] = React.useState();
  const pocetakSedmice = startOfWeek(trenutnaSedmica, { weekStartsOn: 1 });
  const krajSedmice = addDays(pocetakSedmice, 7);


  const dohvatiTermine = async () => {
    try {
      const body = {
        start_date: pocetakSedmice.toISOString(),
        end_date: krajSedmice.toISOString(),
      };
      const odgovor = await axios.post('/termins', body);
      setTermini(odgovor.data);
      console.log(odgovor.data)
      console.log(pocetakSedmice.toISOString());
      console.log(krajSedmice.toISOString());

    } catch (error) {
      console.error("Greška prilikom dohvatanja termina:", error);
    }
  };

  useEffect(() => {
    dohvatiTermine();
  }, [trenutnaSedmica]);

  function openEditTermin(terminId) {
    const termin = termini.find(termin => termin.id === terminId);
    setSelectedOpenTermin(termin);
    setIsOpened(true);
  }



  const renderHeader = () => (
    <div className="flex flex-col sm:flex-row   text-black  border p-2 rounded-t ">


      <div className="flex items-center  ">
        <h1 className='text-black font-bold text-2xl'>Kalendar</h1>
        <span className="ml-4 text-gray-800 text-sm sm:text-base ">{format(pocetakSedmice, "MMMM dd", { locale: bs })} - {format(krajSedmice, "MMMM dd, yyyy", { locale: bs })}</span>
      </div>

      <div className="flex items-center justify-center ml-auto">
        <button
          onClick={() => setTrenutnaSedmica(subWeeks(trenutnaSedmica, 1))}
          className="text-black "
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-8">
            <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
          </svg>
        </button>
        <button
          onClick={() => setTrenutnaSedmica(addWeeks(trenutnaSedmica, 1))}
          className="text-black"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-8">
            <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );

  const renderTermini = (day) => {
    const filteredTermini = termini
      .filter(t => new Date(t.vrijemeTermina).toDateString() === day.toDateString())
      .sort((a, b) => new Date(a.vrijemeTermina) - new Date(b.vrijemeTermina));

    if (filteredTermini.length === 0) {
      return <div className="flex-grow"></div>; // Empty div to fill space
    }

    return filteredTermini.map((termin, index) => {
      const vrijemeTermina = new Date(termin.vrijemeTermina);
      return (
        <div
          key={`${termin.id}-${index}`}
          className=""
        >
          <button className={`${termin.status === "pending" ? "bg-blue-300 border-l-blue-600 " : termin.status === "sent" ? "bg-green-300 border-l-green-600" : " bg-red-300 border-l-red-600"}  border-l-[3px] my-[2px]  justify-center items-start rounded text-black text-sm w-full  flex flex-col   h-[50px] border-b border-gray-200`} onClick={() => openEditTermin(termin.id)}>
            <span className="ml-2">{termin.imeKlijenta} </span>
            <div className="w-full items-center justify-between flex ">
              <span className="ml-2 font-bold ">{format(vrijemeTermina, "HH:mm")} </span>
              <span className="font-bold  mr-2">{termin.telefon}</span>
            </div>
          </button>
        </div>
      );
    });
  };

  return (
    <div className="bg-white  rounded-lg h-full   overflow-x-hidden  ">
      <EditTermin dohvatiTermine={dohvatiTermine} isOpened={isOpened} setIsOpened={setIsOpened} termin={selectedOpenTermin} termini={termini} setTermini={setTermini} toastMessage={toastMessage} isToastOpen={isToastOpen} setIsToastOpen={setIsToastOpen} isToastError={isToastError} setIsToastError={setIsToastError} setToastMessage={setToastMessage}  />
      {renderHeader()}
      <div className="shadow-lg border h-full overflow-x-auto  ">
        <div className="min-w-[1430px]">
          <div className="grid grid-cols-7 bg-gray-200 text-black ">
            {Array.from({ length: 7 }, (_, i) => (
              <div key={i} className="p-2 text-center font-bold">
                {format(addDays(pocetakSedmice, i), "EEEE dd", { locale: bs })}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 h-full ">
            {Array.from({ length: 7 }, (_, i) => {
              const day = addDays(pocetakSedmice, i);

              return (
                <div key={i} className="flex flex-col p-2 border-r relative h-full">
                  {renderTermini(day)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default PregledRasporeda;
