import React from 'react';
import axios from '../axios';

export default function ConfirmModal({ isConfirmOpened, setIsConfirmOpened, termin, termini, setTermini, setIsOpened , toastMessage, isToastOpen, isToastError, setIsToastError, setIsToastOpen, setToastMessage}) {

  const izbrisiTermin = async () => {
    setIsConfirmOpened(false);
    try {
      const response = await axios.delete(`/izbrisi/${termin.id}`);
      
      if(response.status === 200){
        setIsOpened(false)
        console.log(response.status);
        setTermini((prevTermini) => prevTermini.filter((termin1) => termin1.id !== termin.id));
        setToastMessage("Uspješno izbrisan termin")
        setIsToastError(false)
        setIsToastOpen(true)
      }
      
    } catch (error) {
      console.error("Greška prilikom brisanja termina:", error);
      setToastMessage("Greška prilikom brisanja termina")
      setIsToastOpen(true)
      setIsToastError(true)
    }
  };

  return (
    <div className={`w-full absolute h-full z-[9999999999999] top-0 left-0 flex items-center justify-center ${isConfirmOpened ? "" : "hidden"}`}>
      <div className='w-full h-full bg-gray-500 opacity-50 absolute top-0 left-0'></div>
      <div className='rounded bg-white shadow-lg z-10 opacity-100 p-4 sm:w-[400px] w-[90%]'>
        <span className='text-black font-semibold text-lg text-center block mb-2'>
          Da li ste sigurni da želite da izbrišete termin?
        </span>
        <span className='text-gray-500 block mb-6'>
          Izbrisani termin se ne može vratiti nazad.
        </span>
        <div className='flex items-center justify-end'>
          <button
            className='text-gray-500 border border-gray-500 p-2 rounded px-6 mr-2'
            onClick={() => setIsConfirmOpened(false)}
          >
            Poništi
          </button>
          <button
            className='bg-red-500 p-2 rounded text-white px-6'
            onClick={izbrisiTermin}
          >
            Izbriši
          </button>
        </div>
      </div>
    </div>
  );
}
