
import React from 'react';
import { Helmet } from 'react-helmet-async';
const NotFound = () => {
    return (
        <div className='w-full flex min-h-screen items-center justify-center bg-gray-800'>

            <Helmet>
                <title>404 - Not found</title>
            </Helmet>
            <div className='w-full max-w-lg'>
                <h1 className='text-white font-bold text-[100px] mx-4 text-center'>404</h1>
                <h1 className='text-white text-3xl font-bold mx-4 text-center'>Stranica se može naći😅</h1>
                <div className='w-full flex items-center justify-center'>
                    <ul className='mt-8 flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 '>
                        <li className=' '>
                            <a href="/" className='bg-blue-800 text-white py-2  rounded  block w-[200px] text-center'>Početna</a>
                        </li>
                        <li>
                            <a href="/login" className='bg-blue-800 text-white py-2  rounded block w-[200px]  text-center'>Nalog</a>
                        </li>
                        <li>
                            <a href="/kontakt" className='bg-blue-800 text-white py-2  rounded  w-[200px]  block text-center'>Kontakt i Podrška</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
