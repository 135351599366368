import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import Drawer from '../components/Drawer';
import { Helmet } from 'react-helmet-async';
import ScheduleView from '../components/ScheduleView';
import EditTermin from '../components/EditTermin';
import Toast from '../components/Toast';
import axios from '../axios';


export default function Racun() {
    const { user } = useAuth();
    const [toastMessage, setToastMessage] = React.useState('');
    const [isToastOpen, setIsToastOpen] = React.useState(false);
    const [isToastError, setIsToastError] = React.useState();
    const [userData, setUserData] = React.useState();
    const [templetes, setTempletes] = React.useState();

    const maxLength = 150;
    const [apiError, setApiError] = React.useState("")
    const [formData, setFormData] = React.useState({
        message: '',
    });
    const [errors, setErrors] = React.useState({
        message: '',
    });


    const dohvatiTemplejte = async () => {
        try {

            const odgovor = await axios.get('/templetes');
            setTempletes(odgovor.data);
            console.log(odgovor.data)

        } catch (error) {
            console.error("Greška prilikom dohvatanja termina:", error);
        }
    };

    React.useEffect(() => {
        dohvatiTemplejte();
    }, []);

    const handleSubmit = async (e) => {
        const body = {
            "templetePoruka": formData.message,
        };
        try {
            const response = await axios.post('/napraviTemplete', body);
            console.log(response)
            console.log(formData)
            if (response.status === 200) {
                setToastMessage('Templete je uspješno napravljen.');
                setIsToastOpen(true);
                setApiError("")
                setFormData({
                    message: '',
                })
                dohvatiTemplejte()
            }

        } catch (error) {
            setApiError("Neki problem se dogodio, molim vas probajte kasnije.");
            console.log(error)
        }
    }


    const izbrisiTemplete = async (templete) => {
        try {
          const response = await axios.delete(`/izbrisitemplete/${templete.id}`);
          
          if(response.status === 200){
            console.log(response.status);
            setTempletes((prevTermini) => prevTermini.filter((templete1) => templete1.id !== templete.id));
            setToastMessage("Uspješno izbrisan templejt")
            setIsToastError(false)
            setIsToastOpen(true)
          }
          
        } catch (error) {
          console.error("Greška prilikom brisanja termina:", error);
          setToastMessage("Greška prilikom brisanja termina")
          setIsToastOpen(true)
          setIsToastError(true)
        }
      };




    return (
        <div className='flex-grow flex flex-col' >

            <Helmet>
                <title>Templjeti poruka</title>
            </Helmet>
            <Toast message={toastMessage} isOpen={isToastOpen} setIsOpen={setIsToastOpen} isToastError={isToastError} />
            <div className='flex-grow flex flex-col'>
                <h1 className='text-black font-bold text-2xl mb-6'>Templejti poruka</h1>
                <div className='flex items-center xl:items-start justify-between xl:space-x-12 flex-col xl:flex-row'>

                    <div className='bg-white p-4 rounded-lg shadow-lg w-[100%] xl:mt-0 mt-4 xl:w-[90%] border border-gray-100'>
                        <span className='text-black font-semibold text-lg'>Vaši templejti</span>
                        <p className="text-gray-600">Ovdje možete vidjeti sve svoje spremljene templejte poruka koje možete koristiti prilikom slanja obavijesti korisnicima.</p>

                        <div> 
                            {templetes ? templetes.map((templete) => (
                                <div key={templete.id} className='w-full bg-slate-100  rounded-lg my-2 p-2 flex justify-between'>
                                    <span className='text-gray-700 w-full '>{templete.templetePoruka}</span>

                                    <div onClick={()=> izbrisiTemplete(templete)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-red-400  hover:text-red-700 transition">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </div>

                                </div>
                            )) : ""}
                        </div>




                        <div className="mt-12">
                            <span className='text-black font-semibold text-xl '>Napravi novi templjet</span>
                            <h3 className="text-md font-semibold mb-2 text-gray-600">Dinamičke varijable u poruci:</h3>
                            <p className="text-gray-600">Kako biste personalizovali svoje poruke, možete koristiti sljedeće dinamičke varijable:</p>
                            <ul className="list-disc ml-6 text-gray-600 mt-2">
                                <li><strong>(ime)</strong> – Automatski se zamjenjuje imenom korisnika.</li>
                                <li><strong>(prezime)</strong> – Automatski se zamjenjuje prezimenom korisnika.</li>
                                <li><strong>(vrijemetermina)</strong> – Automatski se zamjenjuje datumom i vremenom termina.</li>
                            </ul>
                            <p className="text-gray-600 mt-2">Koristite varijable tačno onako kako su napisane (u zagradama i malim slovima).</p>
                        </div>


                        <div className="bg-blue-100 p-4 rounded-lg">
                            <h4 className="font-semibold mb-2 text-black">Primjer poruke:</h4>
                            <p className="text-gray-700">"Dobar dan (ime), podsjećamo vas da imate zakazan termin danas u (vrijemetermina)."</p>
                        </div>
                        <div className='flex-col flex mt-3'>
                            <label className='text-black'>Poruka</label>
                            <div className='w-full'>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    className={`rounded px-2 py-2 bg-slate-200 mt-[1px] w-full text-black ${errors.message ? 'border-red-500' : ''}`}
                                    placeholder='Ovdje unesite vašu templejt poruku zajedno sa dinamičkim varijablama'
                                    onChange={(e) => {
                                        const text = e.target.value;

                                        // Function to replace specific characters with GSM-7 equivalents
                                        const replaceSpecialChars = (text) => {
                                            return text
                                                .replace(/č/g, 'c')
                                                .replace(/š/g, 's')
                                                .replace(/ć/g, 'c')
                                                .replace(/đ/g, 'd')
                                                .replace(/ž/g, 'z');
                                        };

                                        // Function to filter out non-whitelisted characters
                                        const filterAllowedChars = (text) => {
                                            return text
                                                .split('')
                                                .filter(char => /[A-Za-z0-9\s!.,()@\-_$?+*:"']/g.test(char)) // Whitelist allowed characters
                                                .join('');
                                        };

                                        // Replace specific characters and then filter out non-whitelisted characters
                                        let processedText = replaceSpecialChars(text);
                                        processedText = filterAllowedChars(processedText);

                                        // Limit the message length to maxLength characters
                                        if (processedText.length > maxLength) {
                                            processedText = processedText.substring(0, maxLength);
                                        }

                                        // Update the form data with the processed message
                                        setFormData({ ...formData, message: processedText });
                                    }}
                                    maxLength={maxLength} // Optionally enforce limit at the HTML level
                                />
                                <div className={`text-sm  mt-1 ${formData.message.length === 160 ? "text-red-400" : "text-gray-600"}`}>
                                    {formData.message.length}/{maxLength}
                                </div>
                            </div>





                            {errors.message && <span className='text-red-500'>{errors.message}</span>}
                            {apiError && <span className='text-red-500'>{apiError}</span>}
                        </div>

                        <div className='w-full justify-end flex mt-6'>
                            <button type="submit" className='bg-blue-500 rounded p-2 text-sm px-4' onClick={handleSubmit}>Napravi </button>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
}