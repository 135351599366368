import React from "react";
import LandingDrawer from "./LandingDrawer";

export default function LandingNav({ isVisitorGuest }) {
    return (
        <div className="bg-[#D9F7FF]">

            <nav className="header flex 580:flex-row flex-col items-center justify-between  py-4 max-w-[1300px] mx-4 sm:mx-auto">


                <div className="font-bold text-black  580:flex items-center justify-center  ">
                    <a href="https://napomenime.com"><img src="/images/napomenimelogo.png" alt="Napomeni me logo" className="w-[180px] ml-2" /></a>
                </div>
                <div className="flex items-center justify-center gap-4 mt-4 580:mt-0 580:gap-0 380:flex-row flex-col">
                    <ul className="gap-2 sm:gap-x-12 font-semibold text-gray-700 flex">
                        <li><a href="/cijenovnik">Cijena</a></li>
                        <li><a href="/kontakt">Kontakt</a></li>
                        <li><a href="/login">Prijavi se</a></li>
                    </ul>
                    <div className={` items-center flex  580:hidden  p-[2px] h-10 rounded-lg  text-gray-100 cursor-pointer  bg-blue-950`}>
                        {isVisitorGuest ?
                            <a className='bg w-full h-full flex rounded-lg  items-center' href="/register">
                                <span className='mx-2 font-semibold mr-3'>Napravi nalog</span>
                            </a>
                            :
                            <a className='bg-uicolor w-full h-full flex rounded-lg  items-center' href="/login">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 ml-4 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                                </svg>
                                <span className='mx-2 font-semibold mr-3'>Prijavi se</span>
                            </a>

                        }
                    </div>
                </div>
                <div>
                    <div className={` items-center hidden  580:flex  p-[2px] h-10 rounded-lg  text-gray-100 cursor-pointer  bg-blue-950 mr-4 xl:mr-0`}>
                        {isVisitorGuest ?
                            <a className='bg w-full h-full flex rounded-lg  items-center' href="/register">
                                <span className='mx-2 font-semibold mr-3'>Napravi nalog</span>
                            </a>
                            :
                            <a className='bg-uicolor w-full h-full flex rounded-lg  items-center' href="/login">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 ml-4 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                                </svg>
                                <span className='mx-2 font-semibold mr-3'>Prijavi se</span>
                            </a>

                        }
                    </div>
                </div>
            </nav>

        </div>
    )
}